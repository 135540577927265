import clsx from "clsx";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { NavLink, useHistory } from "react-router-dom";

import { logoutUser } from "../actions/dashboard";
import { deployedRussia } from "../common/utils";
import { useUpdateEffect } from "../hooks/use-update-effect";
import { useAppDispatch, useAppSelector } from "../store";
import { PersonName } from "./common/PersonName";
import styles from "./profile_menu.module.css";
import { AlertBell } from "./profile_menu_bell";
import { AlertShipments } from "./profile_menu_shipments";
import { AlertSmilesBell } from "./profile_menu_smiles";

export function ProfileMenu() {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const smiles = useAppSelector((state) => state.smiles);
  const tour = useAppSelector((state) => state.tour);

  useUpdateEffect(() => {
    if (!user.account_id) {
      history.replace("/login");
    }
  }, [user.account_id, history]);

  const has_signed_offer = user.has_signed_offer || (deployedRussia() && intl.locale == "en");
  const show_smiles = smiles && Object.keys(smiles).length > 0;

  async function logout() {
    await dispatch(logoutUser());
    history.push("/login");
  }

  return (
    <div className="top-menu">
      <ul className="nav navbar-nav pull-right">
        <li className="separator hide"></li>

        {has_signed_offer && show_smiles && deployedRussia() ? (
          <AlertSmilesBell smiles={smiles} enableLink={intl.locale == "ru"} />
        ) : null}

        {has_signed_offer ? (
          <AlertBell style={{ padding: "22px 12px", pointerEvents: tour ? "none" : undefined }} />
        ) : null}

        {has_signed_offer ? (
          <AlertShipments
            style={{ padding: "22px 12px", pointerEvents: tour ? "none" : undefined }}
          />
        ) : null}

        <li className="dropdown dropdown-user">
          <button
            className={clsx("dropdown-toggle", styles.dropdownToggle, { disabled: tour })}
            data-toggle="dropdown"
            data-hover="dropdown"
            data-close-others="true"
            id="top-dd-menu"
            aria-label={intl.formatMessage({ id: "profile_menu" })}
            aria-controls="profile-menu-dropdown-menu"
          >
            <span className="hideOnMobile" id="current-user">
              <PersonName person={user} isDoctor />
            </span>
            <img alt="" className="img-circle" src="/img/avatars/no-avatar.svg" />
          </button>

          <ul id="profile-menu-dropdown-menu" className="dropdown-menu dropdown-menu-default">
            {has_signed_offer ? (
              <>
                <li>
                  <NavLink to="/pages/my_account" id="top-dd-menu-my-account">
                    <i aria-hidden className="icon-user" />
                    <FormattedMessage id="top.menu.profile" />
                  </NavLink>
                </li>

                <li>
                  <NavLink to="/pages/doctor/clinics" id="top-dd-menu-clinic-list">
                    <i aria-hidden className="icon-layers" />
                    <FormattedMessage id="top.menu.my_practices" />
                  </NavLink>
                </li>

                {deployedRussia() ? (
                  <li>
                    <NavLink to="/pages/doctor/clinic/create" id="top-dd-menu-clinic-create">
                      <i aria-hidden className="icon-plus" />
                      <FormattedMessage id="top.menu.add_practices" />
                    </NavLink>
                  </li>
                ) : null}

                <li className="divider"></li>
              </>
            ) : null}

            <li>
              <a onClick={logout} id="top-dd-menu-logout">
                <i aria-hidden className="icon-key" />
                <FormattedMessage id="top.menu.logout" />
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
}
