import React from "react";
import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";

import { postBonusPatient } from "../../../actions/doctor_bonuses";
import { endTreatment } from "../../../actions/end_treatment";
import { isChildrenShortCourse } from "../../../common/courses";
import { getLastCorrection } from "../../../common/patient";
import { deployedRussia, deployedUSA } from "../../../common/utils";
import type { TPatient } from "../../../reducers/dashboard";
import { useAppDispatch, useAppSelector } from "../../../store";
import { Portlet, PortletTitle } from "../../ui/portlet";

export function PatientAdditionalServices({ patient }: { patient: TPatient }) {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);

  const endTreatmentButton = (
    <div className="form-group">
      <button className="btn gray" onClick={() => dispatch(endTreatment(patient.patient_id))}>
        <FormattedMessage id="pat.status.button.end" />
      </button>
    </div>
  );

  const postBonusPatientButton = (
    <div className="form-group">
      <button
        className="btn green"
        onClick={() => dispatch(postBonusPatient(user.account_id, patient.patient_id))}
      >
        <FormattedMessage id="bonus.patient.btn" />
      </button>
    </div>
  );

  return (
    <Portlet>
      <PortletTitle as="h2" iconClassName="icon-speech">
        <FormattedMessage id="pat.block.right.additional" />
      </PortletTitle>

      <div className="portlet-body">
        <div className="row">
          <div className="col-md-4">
            <CorrectionButton patient={patient} />
            <RetainersButton patient={patient} />
            {getLastCorrection(patient).order_options.can_end_treatment ? endTreatmentButton : null}
          </div>

          <div className="col-md-3 col-md-offset-13">
            {patient.bonus_set_free ? postBonusPatientButton : null}
          </div>
        </div>
      </div>
    </Portlet>
  );
}

function CorrectionButton({ patient }: { patient: TPatient }) {
  const course = patient.course.course;
  const can_order_correction = getLastCorrection(patient).order_options.can_order_correction;

  if (deployedRussia() && can_order_correction) {
    return (
      <div className="form-group">
        <NavLink to={`/pages/patient/${patient.patient_id}/correct`} className="btn green">
          <FormattedMessage
            id={
              isChildrenShortCourse(patient.course.course_id)
                ? "pat.status.button.additional_course"
                : "pat.status.button.correction"
            }
          />
        </NavLink>
      </div>
    );
  }

  if (deployedUSA()) {
    return (
      <div className="form-group prescription_tooltip_show">
        <NavLink
          to={`/pages/patient/${patient.patient_id}/correct`}
          className={can_order_correction ? "btn green" : "btn green disabled"}
        >
          <FormattedMessage id="pat.status.button.correction" />
        </NavLink>
        {!can_order_correction && deployedUSA() ? (
          <span className="d-inline-block prescription_tooltip">
            {renderCorrectionBtnTooltip(course)}
          </span>
        ) : null}
      </div>
    );
  }

  return null;

  // --------------------------------------------------------------------------------

  function renderCorrectionBtnTooltip(course: TPatient["course"]["course"]) {
    if (course == "None") {
      return <FormattedMessage id="tooltip.correction.btn.no.course" />;
    }

    if (course == "C_FULL" || course == "C_UNLIMITED" || course == "C_ONE_JAW") {
      return (
        <>
          <h6>
            <FormattedMessage id="tooltip.correction.btn.title" />
          </h6>
          <ul style={{ marginRight: "30px" }}>
            <FormattedMessage id="tooltip.correction.btn.payment.status" />
            <br />
            <FormattedMessage id="tooltip.correction.btn.warranty.date" />
            <br />
          </ul>
          <FormattedMessage id="tooltip.correction.btn.error" />
          <br />
          <NavLink to="/pages/support">
            <FormattedMessage id="tooltip.support" />
          </NavLink>
        </>
      );
    }

    return <FormattedMessage id="tooltip.corrcetion.btn.support" />;
  }
}

function RetainersButton({ patient }: { patient: TPatient }) {
  const course = patient.course.course;
  const can_order_retainers = getLastCorrection(patient).order_options.can_order_retainers;

  if (deployedRussia() && can_order_retainers) {
    return (
      <div className="form-group">
        <NavLink to={`/pages/patient/${patient.patient_id}/retainers`} className="btn green">
          <FormattedMessage id="pat.status.button.retainers" />
        </NavLink>
      </div>
    );
  }

  if (deployedUSA()) {
    return (
      <div className="form-group prescription_tooltip_show">
        <NavLink
          to={`/pages/patient/${patient.patient_id}/retainers`}
          className={can_order_retainers ? "btn green" : "btn green disabled"}
        >
          <FormattedMessage id="pat.status.button.retainers" />
        </NavLink>

        {course === "C_RETAINERS" ? (
          <span className="d-inline-block prescription_tooltip">
            <FormattedMessage id="tooltip.retainers" />

            <NavLink to="/pages/support">
              <FormattedMessage id="tooltip.support" />
            </NavLink>
          </span>
        ) : null}
      </div>
    );
  }

  return null;
}
