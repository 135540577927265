import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { NavLink } from "react-router-dom";

import { getDoctorsTasks } from "../actions/dashboard";
import bellIcon from "../assets/img/bell.svg";
import { deployedUSA } from "../common/utils";
import { useAppDispatch, useAppSelector } from "../store";

export function AlertBell({ style }: { style?: React.CSSProperties }) {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const doctorTasks = useAppSelector((state) => state.doctorTasks);
  const numberOfTasks = doctorTasks.patients.length;

  useEffect(() => {
    if (!doctorTasks.hasBeenLoaded) {
      dispatch(getDoctorsTasks());
    }
  }, [doctorTasks.hasBeenLoaded, dispatch]);

  return (
    <li className="dropdown dropdown-extended dropdown-notification tip">
      <NavLink
        data-toggle="tooltip"
        title={deployedUSA() ? intl.formatMessage({ id: "tooltip_notifications" }) : undefined}
        style={style}
        to="/pages/tasks"
        className="dropdown-toggle"
        data-hover="dropdown"
        data-close-others="true"
        id="tasks-bar"
      >
        <img src={bellIcon} alt="" />
        {numberOfTasks > 0 ? (
          <span className="badge badge-danger" id="tasks-count">
            {numberOfTasks}
          </span>
        ) : null}
      </NavLink>
    </li>
  );
}
