import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { NavLink } from "react-router-dom";

import { getDoctorsPays } from "../actions/dashboard";
import { deployedUSA } from "../common/utils";
import { useAppDispatch, useAppSelector } from "../store";
import { Badge } from "./ui/badge";

export function AlertMenuItem() {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.user);
  const doctorPays = useAppSelector((state) => state.doctorPays);
  const numberOfPayments = doctorPays.patients.length;

  useEffect(() => {
    // When you log out, this component is rerendered, and doctorTasks.hasBeenLoaded is false,
    // so the request fires.
    // That's why we check if user is authenticated before trying to load the payments.
    if (user.account_id && !doctorPays.hasBeenLoaded) {
      dispatch(getDoctorsPays());
    }
  }, [user.account_id, doctorPays.hasBeenLoaded, dispatch]);

  return (
    <li className="nav-item start">
      <NavLink
        data-toggle="tooltip"
        title={deployedUSA() ? intl.formatMessage({ id: "tooltip_payment" }) : undefined}
        to="/pages/payments"
        className="nav-link"
        id="sidebar-payment"
      >
        <i aria-hidden className="icon-wallet" />
        <span className="title">
          <FormattedMessage id="left.menu.my_payments" />
        </span>
        {numberOfPayments > 0 ? <Badge id="tasks-count">{numberOfPayments}</Badge> : null}
      </NavLink>
    </li>
  );
}
