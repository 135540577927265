import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import Skeleton from "react-loading-skeleton";

import { type TExpert, useGetMeetingsExpertsQuery } from "~/api";
import { formatPersonName } from "~/components/common/PersonName";
import { RemoteLogErrorBoundary } from "~/components/common/remote-log-error-boundary";
import { Button } from "~/components/ui/button";
import { Img } from "~/components/ui/image";
import { Layout } from "~/components/ui/layout";
import { Portlet, PortletTitle } from "~/components/ui/portlet";
import { API_MEETINGS_IMAGE } from "~/config";
import { useIntlDocumentTitle } from "~/hooks/use-document-title";

import { CabinetWidget } from "./cabinet-widget";
import expertNoAvatarImg from "./expert-no-avatar.png";

export default function MeetingsPage() {
  useIntlDocumentTitle("meetings.title");

  const [selectedExpert, setSelectedExpert] = useState<TExpert | null>(null);
  const goBack = useCallback(() => setSelectedExpert(null), []);

  return (
    <Layout contentClassName="max-sm:-tw-mx-2 max-sm:-tw-my-6">
      <Portlet as="main">
        <PortletTitle iconClassName="icon-calendar">
          <FormattedMessage id="meetings.title" />
        </PortletTitle>

        <RemoteLogErrorBoundary component="meetings-page">
          <div className="portlet-body">
            {selectedExpert ? null : <ExpertsList onExpertClick={setSelectedExpert} />}
            {selectedExpert ? <CabinetWidget expert={selectedExpert} onBackClick={goBack} /> : null}
          </div>
        </RemoteLogErrorBoundary>
      </Portlet>
    </Layout>
  );
}

function ExpertsList({ onExpertClick }: { onExpertClick(expert: TExpert): void }) {
  const expertsQuery = useGetMeetingsExpertsQuery();

  if (expertsQuery.isUninitialized || expertsQuery.isLoading) {
    return (
      <ul role="list" className="tw-flex tw-list-none tw-flex-col tw-gap-4 tw-px-0">
        {Array.from({ length: 6 }, (_, index) => (
          <Expert key={index} expert={undefined} onExpertClick={onExpertClick} />
        ))}
      </ul>
    );
  }

  if (expertsQuery.isError) {
    return (
      <div className="tw-space-y-4 tw-p-3.5 tw-text-center">
        <strong className="tw-text-base tw-text-red-500">
          <FormattedMessage id="meetings.error" />
        </strong>

        <p className="tw-my-0 tw-text-gray-400">
          <FormattedMessage id="events.error.description" />
        </p>
      </div>
    );
  }

  const noExpertsAvailable = expertsQuery.data.length == 0;

  if (noExpertsAvailable) {
    return (
      <div className="tw-space-y-4 tw-p-3.5 tw-text-center">
        <strong className="tw-text-base">
          <FormattedMessage id="meetings.not.found" />
        </strong>

        <p className="tw-my-0 tw-text-gray-400">
          <FormattedMessage id="meetings.not.found.description" />
        </p>
      </div>
    );
  }

  return (
    <ul role="list" className="tw-flex tw-list-none tw-flex-col tw-gap-4 tw-px-0">
      {expertsQuery.data.map((expert) => (
        <Expert key={expert.account_id} expert={expert} onExpertClick={onExpertClick} />
      ))}
    </ul>
  );
}

function Expert({
  expert,
  onExpertClick,
}: {
  expert: TExpert | undefined;
  onExpertClick(expert: TExpert): void;
}) {
  const description = expert ? getDescription(expert.account_id) : "";

  const imageLoadingElement = <Skeleton width={75} height={75} className="tw-rounded-full" />;

  const imageElement = expert ? (
    <Img
      width={75}
      height={75}
      errorFallback={
        <Img
          width={75}
          height={75}
          errorFallback={null}
          loadingFallback={imageLoadingElement}
          src={expertNoAvatarImg}
        />
      }
      loadingFallback={imageLoadingElement}
      src={expert.avatar ? API_MEETINGS_IMAGE(expert.account_id, expert.avatar) : expertNoAvatarImg}
      alt=""
    />
  ) : (
    imageLoadingElement
  );

  const nameElement = expert ? (
    <span>
      {formatPersonName({ person: expert, useMiddleName: true })}
      {description ? (
        <span className="tw-block tw-text-xs tw-text-gray-400">{description}</span>
      ) : null}
    </span>
  ) : (
    <Skeleton
      className="tw-rounded-md"
      containerClassName="tw-space-y-1.5"
      count={2}
      width={200}
      height={15}
    />
  );

  const buttonElement = expert ? (
    <Button rounded className="tw-uppercase" onClick={() => onExpertClick(expert)}>
      <FormattedMessage id="meetings.book" />
    </Button>
  ) : (
    <Skeleton className="tw-rounded-md" width={120} height={36} style={{ lineHeight: "20px" }} />
  );

  return (
    <li>
      <div className="sm:tw-hidden">
        <div className="tw-flex tw-items-center tw-gap-8">
          {imageElement}

          <div className="tw-flex tw-flex-col tw-gap-4">
            {nameElement}

            <div className="tw-grow-0">{buttonElement}</div>
          </div>
        </div>

        <hr className="tw-mb-0 tw-mt-4" />
      </div>

      <div className="tw-hidden tw-items-center tw-justify-between sm:tw-flex">
        <div className="tw-flex tw-items-center tw-gap-3">
          {imageElement}
          {nameElement}
        </div>

        {buttonElement}
      </div>
    </li>
  );
}

function getDescription(expertId: number): string {
  const MOLOTOVA_ACCOUNT_ID = 3320;
  const SOLOP_ACCOUNT_ID = 3342;

  switch (expertId) {
    case MOLOTOVA_ACCOUNT_ID:
      return "ДЕТИ";
    case SOLOP_ACCOUNT_ID:
      return "ДВНЧС";
    default:
      return "";
  }
}
