import React, { useEffect } from "react";
import { FormattedMessage, type MessageDescriptor, useIntl } from "react-intl";
import { NavLink } from "react-router-dom";

import { getAllShipments } from "../actions/deliveries";
import shipmentsIcon from "../assets/img/shipment_1.svg";
import { deployedUSA } from "../common/utils";
import { useAppDispatch, useAppSelector } from "../store";

export function AlertShipments({
  titleLabel,
  style,
}: {
  titleLabel?: MessageDescriptor["id"];
  style?: React.CSSProperties;
}) {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.user);
  const shipments = useAppSelector((state) => state.shipments);
  const numberOfShipments = shipments.data.length;

  useEffect(() => {
    // When you log out, this component is rerendered, and shipments.hasBeenLoaded is false,
    // so the request fires and we gen an error notification.
    // That's why we check if user is authenticated before trying to load the shipments.
    if (user.account_id && !shipments.hasBeenLoaded) {
      dispatch(getAllShipments());
    }
  }, [user.account_id, shipments.hasBeenLoaded, dispatch]);

  return (
    <li className="dropdown dropdown-extended dropdown-notification tip">
      <NavLink
        data-toggle="tooltip"
        title={deployedUSA() ? intl.formatMessage({ id: "tooltip_shipment" }) : undefined}
        style={style}
        to="/pages/shipments"
        className="dropdown-toggle"
        data-hover="dropdown"
        data-close-others="true"
        id="shipments-bar"
      >
        <img style={{ width: 30 }} src={shipmentsIcon} alt="" />
        {titleLabel ? (
          <span className="title">
            <FormattedMessage id={titleLabel} />
          </span>
        ) : null}
        {numberOfShipments > 0 ? (
          <span className="badge badge-danger" id="shipments-count">
            {numberOfShipments}
          </span>
        ) : null}
      </NavLink>
    </li>
  );
}
