import {
  API_DOCTOR_PAYMENTS,
  API_DOCTORS_TASKS,
  API_LOGOUT,
  API_PATIENT_IMAGE,
  API_PATIENTS_LIST,
} from "../config";
import type { AppDispatch } from "../store";

function userLogoutFailure(message: string) {
  return { type: "USER_LOGOUT_ERROR", message };
}

function clearState() {
  return { type: "CLEAR_STATE" };
}

export function handleDropped(
  img: File,
  patient_id: number,
  semantics: string,
  id: string,
  correctionNum: undefined,
  clbk: () => void
) {
  return (dispatch: AppDispatch) => {
    const url = API_PATIENT_IMAGE(patient_id, semantics);
    const image = {
      img: img,
      semantics: semantics,
      id: id,
      patient_id: patient_id,
    };
    const fn = img.name.toLowerCase();
    if (fn.endsWith(".jpg") || fn.endsWith(".jpeg") || fn.endsWith(".png")) {
      dispatch({ type: "PATIENT_IMAGE_PREVIEW", image });
    }

    const data = new FormData();
    data.append("file", img);

    const xhr = new XMLHttpRequest();
    xhr.open("POST", url, true);
    xhr.withCredentials = true;
    xhr.upload.onprogress = (e) =>
      $(`#__PROGRESSBAR__-${id}`).css({
        width: `${(e.loaded / e.total) * 100}%`,
      });
    xhr.onload = (e) => {
      const json = JSON.parse(e.target.responseText);
      if (json.status === "ok") {
        // если передается correctionNum (номер коррекции)
        // тогда имя фотографии меняется на correction_номер_семантика
        // поле semantics меняется на recipe
        if (correctionNum) {
          let file_extension = json.user_filename.split(".").slice(-1)[0];
          let new_filename =
            "correction_" + correctionNum + "_" + json.semantics + "." + file_extension;
          dispatch({
            type: "PATIENT_IMAGE_UPLOAD_SUCCESS",
            json: Object.assign(
              {},
              json,
              { id },
              { semantics: "recipe" },
              { user_filename: new_filename }
            ),
          });
        } else {
          dispatch({
            type: "PATIENT_IMAGE_UPLOAD_SUCCESS",
            json: Object.assign({}, json, { id }),
          });
        }
        !clbk || clbk();
        $(`#__PROGRESSBAR__-${id}`).hide();
        // $(`#__PROGRESSBAR__-${ id }`).css({ width: '0%' });
      } else {
        //dispatch({type: 'PATIENT_IMAGE_UPLOAD_ERROR', json: Object.assign({}, json, {id})});
        $(`#__PROGRESSBAR__-${id}`).hide();
      }
    };
    xhr.onerror = (e) => {
      //dispatch({type: 'PATIENT_IMAGE_UPLOAD_ERROR', json: Object.assign({}, {id}, e.target.type)});
      $(`#__PROGRESSBAR__-${id}`).hide();
    };
    $(`#__PROGRESSBAR__-${id}`).show();
    xhr.send(data);

    // fetch(url, {
    //   method: 'POST',
    //   credentials: 'include',
    //   body: data
    // })
    // .then((response) => {
    //   if (!response.ok) {
    //     console.error(`Failed uploading ${url}`);
    //     // throw Error(response.statusText);
    //   }
    //   return response;
    // })
    // .then((response) => response.json())
    // .then(
    //   json => {
    //     if (json.status === "ok") {
    //       // если передается correction_num (номер коррекции)
    //       // тогда имя фотографии меняется на correction_номер_семантика
    //       // поле semantics меняется на recipe
    //       if (correction_num) {
    //         let file_extension = json.user_filename.split('.').slice(-1)[0];
    //         let new_filename = 'correction_'+correction_num+'_'+json.semantics+'.'+file_extension;
    //         dispatch({type: 'PATIENT_IMAGE_UPLOAD_SUCCESS', json: Object.assign({}, json, {id: id}, {semantics: 'recipe'}, {user_filename: new_filename})});
    //       } else {
    //         dispatch({type: 'PATIENT_IMAGE_UPLOAD_SUCCESS', json: Object.assign({}, json, {id: id})})
    //       }
    //     } else {
    //       dispatch({type: 'PATIENT_IMAGE_UPLOAD_ERROR', json: Object.assign({}, json, {id: id})})
    //     }
    //   }
    // )
    // .catch((err) => dispatch({type: 'PATIENT_IMAGE_UPLOAD_ERROR', json: Object.assign({}, {id: id}, err)}))
  };
}

export function getPatientsData(
  status: string | false,
  ordering: "patient_id" | "-patient_id" | "patient_name" | "-patient_name",
  search: string | false,
  page: number,
  perPage: number
) {
  const stat = status ? `status=${status}` : null;
  const order = ordering ? `ordering=${ordering}` : null;
  const srch = search ? `search=${search}` : null;
  const pg = page ? `page=${page}` : null;
  const prPg = perPage ? `perPage=${perPage}` : null;

  let url = `${API_PATIENTS_LIST}?${order}&${pg}&${prPg}`;
  if (status) {
    url += `&${stat}`;
  }
  if (srch) {
    url += `&${srch}`;
  }

  return (dispatch: AppDispatch) => {
    dispatch(loadSpinner());
    fetch(url, { credentials: "include" })
      .then((response) => {
        if (!response.ok) {
          console.error(`Failed on ${API_PATIENTS_LIST}`);
          throw Error(response.statusText);
        }
        return response;
      })
      .then((response) => response.json())
      .then(
        (json) => dispatch({ type: "GET_PATIENTS_SUCCESS", json }),
        (err) => dispatch({ type: "GET_PATIENTS_ERROR", err })
      )
      .catch((err) => dispatch({ type: "GET_PATIENTS_ERROR", err }))
      .finally(() => dispatch(clearSpinner()));
  };
}

export function getDoctorsTasks() {
  return (dispatch: AppDispatch) => {
    fetch(API_DOCTORS_TASKS, { credentials: "include" })
      .then((response) => {
        if (!response.ok) {
          console.error(`Failed on ${API_DOCTORS_TASKS}`);
          throw Error(response.statusText);
        }
        return response;
      })
      .then((response) => response.json())
      .then(
        (json) => dispatch({ type: "GET_DOCTORS_TASKS_SUCCESS", json }),
        (err) => dispatch({ type: "GET_DOCTORS_TASKS_ERROR", err })
      )
      .catch((err) => dispatch({ type: "GET_DOCTORS_TASKS_ERROR", err }));
  };
}

export function getDoctorsPays() {
  return (dispatch: AppDispatch) => {
    fetch(API_DOCTOR_PAYMENTS, { credentials: "include" })
      .then((response) => {
        if (!response.ok) {
          console.error(`Failed on ${API_DOCTOR_PAYMENTS}`);
          throw Error(response.statusText);
        }
        return response;
      })
      .then((response) => response.json())
      .then(
        (json) => dispatch({ type: "GET_DOCTORS_PAYS_SUCCESS", json }),
        (err) => dispatch({ type: "GET_DOCTORS_PAYS_ERROR", err })
      )
      .catch((err) => dispatch({ type: "GET_DOCTORS_PAYS_ERROR", err }));
  };
}

export function logoutUser() {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await fetch(API_LOGOUT, {
        method: "POST",
        headers: { Accept: "application/json" },
        credentials: "include", // send cookies for CORS requests
      });
      if (!response.ok) {
        console.error(`Failed logging out on ${API_LOGOUT}`);
        throw Error(response.statusText);
      }
      dispatch(clearState());
    } catch (err) {
      dispatch(userLogoutFailure("logout failure: " + err));
    }
  };
}

export function eraseStateProp(
  prop_name: "patient" | "media" | "comments" | "instructions" | "doctor"
) {
  return { type: "ERASE_" + prop_name.toUpperCase() };
}

export function cbctUploadSuccess(files) {
  return (dispatch) => {
    files = files.map((file) => file.name);
    dispatch({ type: "CBCT_UPLOAD_SUCCESS", payload: files });
  };
}

function loadSpinner() {
  return { type: "LOAD_SPINNER" };
}

function clearSpinner() {
  return { type: "CLEAR_SPINNER" };
}
